<template>
  <v-card class="px-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row justify="start">
          <v-col cols="auto">
            <span class="title">Assets</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="3">
        <label class="label">Logo</label>
        <mf-upload-image v-model="data.logo" file-name="logo" @snackbar-error="showSnackbarError" @upload="uploadImage($event, 'logo')" />
      </v-col>
      <v-col cols="3">
        <label class="label">Favicon</label>
        <mf-upload-image
          v-model="data.favicon"
          :validation="['image/vnd.microsoft.icon', 'image/png']"
          file-name="Fav Icon"
          label-formats-accepted="ICO ou PNG"
          @snackbar-error="showSnackbarErrorFavico"
          @upload="uploadImage($event, 'favicon')"
        />
      </v-col>
      <v-col cols="3">
        <label class="ml-4 label">Banner</label>
        <div :class="['box-banner'].concat(hasBanner ? [] : ['empty-banner'])">
          <v-img v-if="hasBanner" :src="data.banner" :style="{ height: `${372 / (355 / 140)}px` }" contain class="preview">
            <v-row justify="end">
              <v-col cols="auto">
                <v-btn class="mr-3" x-small fab @click="removeImage"> <mf-icon icon="close" /> </v-btn>
              </v-col>
            </v-row>
          </v-img>
          <v-btn v-else color="primary" @click="dialogUploadBanner = true">Adicionar Banner</v-btn>
        </div>
      </v-col>
    </v-row>
    <dialog-image-cropper
      v-if="dialogUploadBanner"
      v-model="dialogUploadBanner"
      :aspect-ratio="355 / 140"
      :mb-size-limit="10"
      @save-image="uploadImageBase64($event, 'banner')"
    />
  </v-card>
</template>
<script>
import { MUTATION_UPLOAD_IMAGE, MUTATION_UPLOAD_IMAGE_BASE_64 } from '../../graphql/landing-page'
export default {
  name: 'CardImageLandingPage',
  components: {
    DialogImageCropper: () => import('@/components/atomic-components/organism/DialogImageCropper.vue')
  },
  props: {
    dataLandingPage: {
      type: Object,
      required: true
    },
    dbName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogUploadBanner: false,
      data: this.dataLandingPage
    }
  },
  computed: {
    hasBanner() {
      return this.data.banner !== ''
    }
  },
  watch: {
    data: {
      handler() {
        this.$emit('input', this.data)
      },
      deep: true
    },
    dataLandingPage: {
      handler() {
        this.data = this.dataLandingPage
      },
      deep: true
    }
  },
  methods: {
    async uploadImage({ file }, attr) {
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_UPLOAD_IMAGE,
            variables: {
              accountId: this.dataLandingPage?.id,
              file: file
            }
          })
          .then(({ data }) => {
            this.data[attr] = data?.uploadImageLandingPageFranchise?.urlImage
          })
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao realizar upload de imagem.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async uploadImageBase64({ file, fileName }, attr) {
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_UPLOAD_IMAGE_BASE_64,
            variables: {
              accountId: this.dataLandingPage?.id,
              base64: file,
              filename: fileName
            }
          })
          .then(({ data }) => {
            this.data[attr] = data?.uploadBase64LandingPageFranchise?.urlImage
            this.dialogUploadBanner = false
          })
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao realizar upload de imagem.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    removeImage() {
      this.data.banner = ''
    },
    showSnackbarError(snackbar) {
      if (snackbar) {
        let error = snackbar.message === 'Imagens de produtos podem ter no máximo 1 MB' ? 'Imagem pode ter no máximo 1 MB' : snackbar.message
        this.$alert({
          alert_message: error,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    showSnackbarErrorFavico(snackbar) {
      if (snackbar) {
        let error =
          snackbar.message === 'Imagens de produtos podem ter no máximo 1 MB' ? 'Imagem pode ter no máximo 1 MB' : 'Permitido apenas arquivos .ICO e .PNG'
        this.$alert({
          alert_message: error,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>

<style>
.box-banner {
  height: 140px;
  margin: 2vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.empty-banner {
  border: 1.5px solid #ecedf0;
}
.responsive-image {
  width: 100%;
  height: auto;
}
.preview {
  width: 100%;
  overflow: hidden;
}
</style>
